:root {
    --two-columns-padding-middle-per-column: 22px;
}
@media (max-width: 1366px) {
    :root {
        --two-columns-padding-middle-per-column: 14px;
    }   
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --two-columns-padding-middle-per-column: 1vw;
    }   
}

.two-columns, .two-columns-descriptive, .three-columns {
    width: 100%;

    display: flex;
    flex-direction: row;
}



.column {
    display: relative;
}
.two-columns .column:nth-child(1) {
    padding-right: var(--two-columns-padding-middle-per-column);
}
.two-columns .column:nth-child(2) {
    padding-left: var(--two-columns-padding-middle-per-column);
}

/* If it has half the padding */
.two-columns--half-padding > .column:nth-child(1) {
    padding-right: calc(var(--two-columns-padding-middle-per-column) / 2) !important;
}
.two-columns--half-padding > .column:nth-child(2) {
    padding-left: calc(var(--two-columns-padding-middle-per-column) / 2) !important;
}
.two-columns--quarter-padding > .column:nth-child(1) {
    padding-right: calc(var(--two-columns-padding-middle-per-column) / 4) !important;
}
.two-columns--quarter-padding > .column:nth-child(2) {
    padding-left: calc(var(--two-columns-padding-middle-per-column) / 4) !important;
}

.two-columns--normal > .column {
    flex: 1;
}
.two-columns--lefty > .column:nth-child(1) {
    flex: 3;
}
.two-columns--lefty > .column:nth-child(2) {
    flex: 2;
}
  


.two-columns-descriptive > .column {
    flex: 1;
}
.three-columns > .column {
    flex: 1;
}

@media (orientation: portrait), (max-width: 900px)  {
    .two-columns {
        flex-direction: column;
    }
    .two-columns-descriptive > .column:first-child {
        flex: 4;
    }
    .column:nth-child(1) {
        padding-right: 0px;
    }
    .column:nth-child(2) {
        padding-left: 0px;
    }
    
    /* If it has half the padding */
    .two-columns--half-padding > .column:nth-child(1) {
        padding-right: 0px !important;
    }
    .two-columns--half-padding > .column:nth-child(2) {
        padding-left: 0px !important;
    }
    .two-columns--quarter-padding > .column:nth-child(1) {
        padding-right: 0px !important;
    }
    .two-columns--quarter-padding > .column:nth-child(2) {
        padding-left: 0px !important;
    }
}