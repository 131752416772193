
:root {
    --nav-height: 80px;
    --nav-item-padding: 40px;
    --nav-font-size: var(--p-size);
    --nav-background-color: var(--dark-gray-color);
    --nav-hover-color: var(--orange-color);

    --subnav-height: 50px;
    --subnav-item-padding-left: 40px;
    --subnav-item-padding-right: 20px;
    --subnav-background-color: rgba(15, 15, 15, 1);

    --subnav-section-max-width: 225px;
    --subnav-section-min-width: 90px;

    --nav-z-mega-dropdown: 999;
    --nav-z-item: 9999;
    --undernav-padding: 15px;
    --undernav-height: calc(var(--banner-3-height) + var(--undernav-padding) * 2);
}

#Navigation-Section {
    position: fixed;
    height: var(--nav-height);
    width: 100%;
    z-index: 99999999;
    background-color: var(--nav-background-color);

    top: 0px;
}
#Navigation-Section * {
    font-family: HomeFont;
}



nav {
    width: 100%;
    height: var(--nav-height);
    margin: 0px;
    padding: 0px;
    background-color: var(--nav-background-color);
}
.nav-landscape {
    width: 100%;
    height: var(--nav-height);

    display: flex;
    gap: var(--nav-item-padding);

    padding-left: var(--content-left);
}
.nav-item {
    height: 100%;
    z-index: var(--nav-z-item) !important;
    display: inline-block;            /* Ensures correct height as 100% of parent */
    position: relative;               /* For dropdown */

    font-size: var(--nav-font-size);
    letter-spacing: 0.04em;
    line-height: var(--nav-height);   /* Ensures text is centered vertically */
    text-align: center;
    text-decoration: none;
    color: white;
    
    cursor: pointer;
}
.nav-item:hover {
    color: orange;
}
.nav-simple-checkbox {
    margin-top: 27px;
    margin-right: 5px;
    height: 25px;
    width: 25px;
}
.nav-item > a {
    display: inline-block;
    color: white;
    height: 100%;
    width: 100%;
}
.nav-item a:hover {
    color: orange;
}




.mega-dropdown {
    position: absolute;
    
    top: var(--nav-height);
    padding: 0px;
    margin: 0px;

    width: 100vw;
    z-index: var(--nav-z-mega-dropdown);

    border-top: solid var(--nav-hover-color) 2px;
    border-bottom: solid gray 1px;

    background-color: black;
    color: white;
}
.subnav {
    width: 100%;
    padding-left: var(--content-left);
    padding-top: var(--nav-item-padding);
    padding-bottom: var(--nav-item-padding);

    display: flex;
    gap: 60px;
}
@media (orientation: landscape) {
    .subnav--ls-inactive {
        display: none;
    }
    .subnav--ls-active {}
}
.subnav-section {
    max-width: var(--subnav-section-max-width);
    min-width: var(--subnav-section-min-width);
}
.subnav h4 {
    font-family: HomeFont;
    font-weight: 100;
    /* border-bottom: solid orange 2px; */

    margin: 0px;
    padding-bottom: 3px;
}
.subnav-title-underline {
    width: 100%;
    height: 2px;
    background-color: var(--nav-hover-color);
}
.subnav ul {
    margin: 0px;
    padding: 0px;

    margin-top: 12px;
}
.subnav li {
    list-style: none;
}
.subnav li:not(:last-child) {
    margin-bottom: 7px;
}
.subnav li a {
    display: block;                 /* For larger hover area */
    width: 100%;

    color: rgb(167, 167, 167);
    font-family: HomeFont;
    font-size: var(--nav-font-size);

    padding-left: 0px;
    transition-property: padding-left;
    transition-duration: var(--transition-duration);
}
.subnav li a.premium {
    color: var(--light-blue-color) !important;
}
.subnav li a:hover {
    color: var(--nav-hover-color) !important;
    padding-left: 5px;
}
@media (orientation: portrait) {
    .subnav li a {
        transition-property: none;
    }
    .subnav li a:hover {
        padding-left: 0px;
    }
}





.subnav-item {
    font-family: HomeFont;
    font-size: var(--nav-font-size);

    line-height: var(--subnav-height);   /* Ensures text is centered vertically */
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    color: white;
  
    height: 100%;
    z-index: var(--nav-z-item) !important;
    display: inline-block;            /* Ensures correct height as 100% of parent */
    position: relative;               /* For dropdown */
}
.subnav-item a {
    display: inline-block;
    color: white;
    height: 100%;
    width: 100%;
}


@media (orientation: portrait) {
    .nav-logo-div-wrapper {
        display: none;
    }
}
.nav-logo-div {
    position: absolute;
    
    top: calc(var(--nav-height) * 0.45 / 2);
    left: 20px;
    
    height: calc(var(--nav-height) * 0.45);
    /* width: calc(var(--nav-height) * 0.65); */
}
.nav-logo-div img {
    display: block;
    height: 100%;
    width: 100%;
}
.nav-logo-version {
    position: absolute;
    width: 62px;
    bottom: 10px;
    left: 20px;
    
    font-size: 10px;
    text-align: center;
    color: gray;
}
.nav-logo-version:hover {
    color: white;
}





/* ---------- Mobile View ----------- */
@media (orientation: landscape) {
    .burger-icon {
        display: none;
    }
    /* .nav-portrait {
        display: none;
    } */
    .subnav-title-portrait-only {
        display: none;
    }
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --nav-height: 60px;
        --nav-item-padding: 7vw;
        --nav-background-color: rgba(12, 15, 17, 1);
    
        --subnav-height: 50px;
        --subnav-item-padding-left: 30px;
        --subnav-item-padding-right: 15px;
        --subnav-background-color: rgba(15, 15, 15, 1);

        --subnav-section-max-width: 9999px;
        --subnav-section-min-width: 0px;
    
        --nav-z-mega-dropdown: 999;
        --nav-z-item: 9999;
        --undernav-padding: 15px;
        --undernav-height: calc(var(--banner-3-height) + var(--undernav-padding) * 2);
    }
    

    /* Hide landscape nav */
    .nav-landscape {
        display: none;
    }

    /* ---------- Burger ---------- */
    .burger-icon {
        position: absolute;
    
        height: var(--nav-height);
        width: var(--nav-height);
        
        top: 0px;
        right: 0px;
    }
    .burger-icon img {
        display: block;
        height: 100%;
        width: 100%;
    }
    .burger-icon img:hover {
        cursor: pointer;
        opacity: 0.8;
    }
    .burger-icon {
        display: block;
    }

    /* ---------- Nav ---------- */
    /* .nav-portrait {
        display: block;
    } */

    /* ---------- Mega Dropdown ---------- */
    .mega-dropdown {
        position: absolute;
        top: var(--nav-height);
        right: -20px;   /* To hide scrollbar */

        width: 60vw;
        height: calc(100vh - var(--nav-height));

        overflow-y: scroll;

        display: none;
        flex-direction: column;
    }
    .displayed-on-mobile {
        display: flex;
    }
    .subnav {
        width: 100%;
        
        display: flex;
        flex-direction: column;

        padding: calc(var(--nav-item-padding));
        overflow-y: hidden;
    }
    .subnav--portrait-inactive {
        display: none;
    }
    .subnav--portrait-active {
        max-height: 200vh;
        animation: Expanding 2s;
    }
    @keyframes Expanding {
        0% {
            max-height: 0vh;
        }
        100% {
            max-height: 200vh;
        }
    }
    .subnav li a {
        font-size: calc(1.15 * var(--nav-font-size));
    }
    .subnav-item {
        font-size: calc(1.15 * var(--nav-font-size));
    }
    .mega-dropdown h4 {
        font-size: calc(1.15 * var(--nav-font-size));
    }
    h3.subnav-title-portrait-only {
        display: block;

        font-size: calc(1.25 * var(--nav-font-size));
        font-weight: 400;

        margin: 0px;
        padding: calc(var(--nav-item-padding));
        border-bottom: solid var(--orange-color) 1px;
        margin-top: -1px;

        cursor: pointer;
    }
}
