#Canvas-Wrapper canvas {
    display: block;
    border: solid black 1px;
    margin: auto;
    width: 80%;
    margin-top: 20px;
}

#Added-Spells {
    font-family: var(--font-text);
    width: 60%;
    font-size: var(--p-size);
}
#Spell-Choices {
    font-family: var(--font-text);
    width: 100%;
    font-size: var(--p-size);
}
#Added-Spells option, #Spell-Choices option {
    padding: 12px;
}