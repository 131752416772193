.page-h1 {
    margin-top: 0px;
    position: relative;
    z-index: 1;

    padding-top: calc(var(--page-padding));
    padding-bottom: calc(var(--h1-size) * 0.4);
}
.page-h1 h1 {
    margin: 0px;
    margin-left: -4px;

    line-height: var(--h1-size);
    font-weight: 100;
    font-size: var(--h1-size);
    font-family: HomeFontBold;
    color: var(--dark-color);
    /* color: rgb(64, 18, 189); */
}
.page-h1 a {
    color: black;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}