
/* -------------------- File Explained -------------------- */
/* This CSS file contains classes for layout, spacing, buttons, custom text, etc. */
/* All of are generic, and are not part of one specific element. */


/* --------------------- Vars --------------------- */
:root {
  --z-background: 1;
  --z-intermediate: 2;
  --z-under-content: 3;
  --z-content: 4;
  --z-overlay: 5;
}


/* -------------------- Layout -------------------- */
/* Use this to layout the page (e.g. wrap elements in div with these classes */
/* Also use TwoColumns with Column children */
.with-margined-children > * {           /* Direct children only */
    margin-top: 0px !important;
    margin-bottom: var(--element-padding-half) !important;
  }
.margined-bottom {
    margin-top: 0px;
    margin-bottom: var(--element-padding);
}
.unmargined {
    margin-top: 0px;
    margin-bottom: 0px;
}
.padded {
    padding: var(--element-padding);
}
.centered-content {
    width: 100%;
  
    display: flex;
    justify-content: center;
    gap: 20px;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}



/* ----------------- Responsiveness ---------------- */
.landscape-only {
    display: block;
}
.portrait-only {
    display: none;
}

@media (orientation: portrait) {
    .landscape-only {
        display: none !important;
    }
    .portrait-only {
        display: block;
    }
}


/* -------------------- Buttons -------------------- */
.button-standard {
    font-family: StylizedFont;
    font-size: 1em;
  
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    border: solid white 1px;
    border-radius: 4px;
  
    padding: 0px;
  
    z-index: 999;
    display: block;
    position: relative;
  
    cursor: pointer;
}
.button-standard:hover {
    border: solid var(--light-medium-color) 1px;
}
.button-standard__content {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
}


/* -------------------- Text -------------------- */
.text-link-with-hover {
    color: rgb(0, 78, 151);
}
.text-link-with-hover:hover {
    cursor: pointer;
    text-decoration: underline;
    filter: brightness(0.5);
}



.floating-animation-1 {
    animation: Floating1 6s ease-in-out infinite;
}
.floating-animation-2 {
    animation: Floating2 9s ease-in-out infinite;
}
.floating-animation-3 {
    animation: Floating3 5s ease-in-out infinite;
}
.rotating-animation-1 {
    animation: Rotating1 500s infinite;
}
@keyframes Floating1 {
    0% {
        transform: translateY(10px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(10px);
    }
}
@keyframes Floating2 {
    0% {
        transform: translateY(16px) rotate(5deg);
    }
    20% {
        transform: translateY(10px) rotate(5deg);
    }
    70% {
        transform: translateY(20px) rotate(0deg);
    }
    100% {
        transform: translateY(16px) rotate(5deg);
    }
}
@keyframes Floating3 {
    0% {
        transform: translateY(18px);
    }
    40% {
        transform: translateY(10px);
    }
    90% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(18px);
    }
}
@keyframes Rotating1 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}