
:root {
    --side-menu-width: 250px;
    --side-menu-height: 400px;

    --side-menu-top: 174px;
    --side-menu-pad-right: calc(var(--side-menu-top) - var(--nav-height));
    --side-menu-left: calc((100% - var(--page-content-width)) / 2 - var(--side-menu-width) / 2 - 45px);

    --side-menu-item-spacing: 15px;
}
.side-menu {
    position: fixed;
    z-index: var(--z-overlay);
    left: var(--content-left);
    top: var(--side-menu-top);

    background-color: var(--nav-background-color);

    padding-right: 4px;
}
.side-menu.absolute {
    position: absolute;
}
@media (max-width: 1435px) {
    .side-menu {
        left: var(--side-menu-left);
    }
}
@media (max-width: 1366px) {
    .side-menu {
        display: none;
    }
}
.side-menu-content {
    width: var(--side-menu-width);
    max-height: var(--side-menu-height);

    padding: 30px;
    padding-bottom: calc(var(--side-menu-item-spacing) + 3px);   /* Because the bottom-most item has 15px padding-bottom */

    font-family: HomeFont;
    overflow-y: scroll;
    overflow-x: hidden;
}

.side-menu-ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    font-size: 16px;
}
.side-menu-subtitle-li {
    margin-bottom: var(--side-menu-item-spacing);
}
.side-menu-subtitle-li .inline-icon {
    height: 12px;
    margin-top: 4px;
}
.side-menu-subtitle-li a {
    color: gray;
}
.side-menu-h4 {
    margin: 0px;
    margin-bottom: 15px;
    padding-top: 5px;

    color: white;
    font-weight: 100;
    font-size: 18px;
}

.side-menu-subtitle-li a:hover, .side-menu-content h4:hover {
    color: orange;
}



.side-menu-content::-webkit-scrollbar {
    width: 6px;
}
  
/* Track */
.side-menu-content::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; 
    border-radius: 10px; */
    background-color: gray;
    border: solid var(--nav-background-color) 2px;
    margin: 20px;
}

/* Handle */
.side-menu-content::-webkit-scrollbar-thumb {
    background: white;
    width: 6px;
    border-radius: 10px;
}

/* Handle on hover */
.side-menu-content::-webkit-scrollbar-thumb:hover {
    background: orange; 
}