:root {
    --inline-icon-size: calc(var(--p-size));
}
.inline-icon {
    height: var(--inline-icon-size);
    margin-right: 3px;
    margin-top: calc(var(--inline-icon-size) * 0.125);
}
.inline-icon--small-stat {
    height: var(--inline-icon-size);
    margin-right: 4px;
    margin-top: calc(var(--inline-icon-size) * 0.095);
}
.inline-icon--spell {
    height: calc(var(--p-size) * 0.8);
    margin-right: 3px;
}
.inline-icon--spell-downer {
    height: calc(var(--p-size) * 0.8);
    margin-top: 1px;
}