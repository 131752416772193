:root {
    --spell-border-width: 16px;
    --spell-true-border-width: 6px;

    --spell-margin-bottom: calc(var(--element-padding) / 2);
    --spell-padding-bottom: calc(var(--spell-padding) * 1.5);

    --spell-border-z: 20;

    --spell-banner-height: 0px;

    --spell-padding: 16px;
    --spell-passive-title-top-padding: 18.95px;
    --spell-padding-small: 6px;
    --spell-icon-size: 90px;

    --subspell-size-multiplier: 0.85;
    
    --spell-notes-color: #999999;

    --z-background: 20;
    --z-border: 60;
    --z-box: 80;
    --z-title: 100;
    --z-icon-side: 120;
    --z-variant-spinner: 130;
    --z-icon: 140;
    --z-variant-counter: 150;

}
@media (max-width: 1366px) {
    :root {
        --spell-padding: 12px;
        --spell-padding-small: 6px;
        --spell-icon-size: 78px;
    }
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --spell-icon-size: 15vw;

        --spell-padding: 3.5vw;
        --spell-padding-small: 1.325vw;
        /* --spell-passive-title-top-padding: 4.55vw; */
        --spell-passive-title-top-padding: 2.005vw;
    }
}

.spell {
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: var(--spell-margin-bottom);

    white-space: pre-line; /* Uses \n instead of <br> for new lines */

    -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.41); 
    box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.41);
}
.spell a {
    text-decoration: underline;
    color: var(--purple-color);
}

.spell--normal {}
.spell--subspell {
    width: calc(100% * var(--subspell-size-multiplier));
    max-width: calc(var(--subspell-size-multiplier) * var(--spell-max-width));
}
/* Decoration */
.spell__no-border {
    border: solid 2px black;
}
.spell__border {
    position: absolute;
    width: 100%;
    height: 100%;
    border-image: url('spell-borders.png') 16 stretch;
    border-width: var(--spell-border-width);
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    z-index: var(--z-border);
}
.spell--subspell .spell__border {
    border-width: calc(var(--spell-border-width) / 3 * 2);
}
.spell__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: var(--z-background);
    background-color: white;
    
}


/* Actual spell box */
.spell__box {
    height: 100%;
    position: relative;
    margin: auto;
    padding-top: calc(var(--spell-banner-height) + var(--spell-padding-small));
    padding-left: var(--spell-padding-small);
    padding-right: var(--spell-padding-small);
    z-index: var(--z-box);
}


/* Spell Top */
.spell-top {
    display: flex;
    flex-direction: row;
    min-height: calc(var(--spell-icon-size) + 2 * var(--spell-padding));
}
.spell-top__icon-side {
    position: relative;
    padding: var(--spell-padding);
    padding-right: 0px;
    z-index: var(--z-icon-side);
}
.spell-top__icon-side img {
    width: 100%;
    position: relative;
    z-index: var(--z-icon);
}
.spell-top__icon-side > img {
    width: calc(var(--spell-icon-size));
    padding: 1px;
    position: relative;
    z-index: var(--z-icon);
    border: solid black 1px;
}
.spell-top__icon-wrapper {
    width: var(--spell-icon-size);
    height: var(--spell-icon-size);
    padding: 1px;
    border: solid black 1px;
    overflow: hidden;
    position: relative;
}
.spell-top__variant-spinner {
    position: absolute;
    width: calc(var(--spell-icon-size) * 2);
    height: calc(var(--spell-icon-size) * 2);
    left: calc(var(--spell-icon-size) * -0.5);
    top: calc(var(--spell-icon-size) * -0.5);
    z-index: var(--z-variant-spinner);

    background-image: conic-gradient(
        rgb(0, 174, 255) 0%,
        #FFFFFF00 50%,
        rgb(0, 174, 255) 50%,
        #FFFFFF00 100%
    );

    animation-name: VariantSpinning;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes VariantSpinning {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.spell--passive .spell-top__icon-side img {
    border-radius: 25%;
    padding: 1px;
}
.spell--passive .spell-top__icon-wrapper {
    border-radius: 25%;
}
.spell--subspell .spell-top__icon-wrapper {
    width: calc(var(--subspell-size-multiplier) * var(--spell-icon-size));
    height: calc(var(--subspell-size-multiplier) * var(--spell-icon-size));
}
.spell--subspell .spell-top__icon-side img {
    width: calc(var(--subspell-size-multiplier) * var(--spell-icon-size));
    height: calc(var(--subspell-size-multiplier) * var(--spell-icon-size));
}
@keyframes SpellIconBlinking {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 119, 255, 0.8);
    }
    100% {
        box-shadow: 0 0 10px 10px rgba(0, 119, 255, 0.1);
    }
}
.spell--with-variants .spell-top__icon-side img {
    padding: 1px;
}
.spell-top__variant-counter {
    font-family: var(--font-home);
    opacity: 0;
    background-color: white;
    position: absolute;
    width: var(--spell-icon-size);
    height: var(--spell-icon-size);
    
    color: black;
    font-size: calc(0.35 * var(--spell-icon-size));
    text-align: center;
    line-height: var(--spell-icon-size);

    z-index: var(--z-variant-counter);
}
.spell-top__variant-counter:hover {
    user-select: none;
    opacity: 0.5;

    cursor: pointer;
}
.spell-top__title-side {
    flex: 1;
}
.spell-top__title__wrapper {
    width: 94.25%;
    border: solid black 2px;
    border-left: none;
    margin-top: calc(var(--spell-padding) + var(--spell-padding-small));
    display: inline-block;
    padding: 1px;
    z-index: var(--z-title);
}
.spell--passive .spell-top__title__wrapper {
    margin-top: calc(var(--spell-passive-title-top-padding) + var(--spell-padding));
    margin-left: -1px;
}
.spell--subspell.spell--passive .spell-top__title__wrapper {
    margin-top: calc(8px + var(--spell-padding));
    margin-left: -1px;
}
.spell-top__title, .spell-top--iconless__title {
    border: solid black 1px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
    background-color: white;
    color: var(--dark-color);
    
    min-width: 125px;

    padding: calc(var(--spell-padding) / 2);
    padding-left: calc(var(--spell-padding) + 6px);
    padding-right: var(--spell-padding);

    font-weight: bold;
    font-size: var(--p-size);
    letter-spacing: 0.075rem;
    font-family: TitleFont;
    text-align: center;
    white-space: nowrap;
}

.spell-top__stats {
    padding: calc(var(--spell-padding) - 2px); /* For pixel perfect alignment of the text */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;                                  /* Especially for space between lines */
}
.spell-top__stats--no-padding-side {
    padding-left: 0px;
    padding-right: 0px;
}
.spell-top__stats--less-padding-top-bottom {
    padding-top: calc(var(--spell-padding) / 4);
    padding-bottom: calc(var(--spell-padding) / 4);
}
.spell-top__stats div {
    font-family: var(--font-text);
    font-size: calc(var(--p-size) * 0.8);
    line-height: calc(var(--p-size) * 0.9);
    display: inline-block;

    border-radius: 4px;

    margin-right: 9px;
}

.spell-top--iconless__title-wrapper {
    display: block;
    margin: auto;
    margin-top: calc(var(--spell-padding) + var(--spell-padding-small));
    width: 70%;
    z-index: var(--z-title);

    border: solid black 2px;
    padding: 1px;
}




/* Bottom side */
.spell .separator {
    margin-top: 0px;
    margin-bottom: 0px;
    width: calc(100% + 2px);
    margin-left: -1px;
}
.spell-description, .spell-green, .spell-red {
    font-family: var(--font-text);
    font-size: var(--p-size);
    padding: var(--spell-padding);
    padding-top: calc(var(--spell-padding-small) * 1.5);
    padding-bottom: var(--spell-padding-bottom);
}
.spell-green {
    color: rgb(0, 180, 0);
}
.spell-red {
    color: rgb(240, 0, 0);
}
.spell-upgrade {
    padding: var(--spell-padding);
    padding-top: var(--spell-padding-small);
    padding-bottom: var(--spell-padding-bottom);

    font-size: calc(var(--p-size) * 0.8);
}
.spell-notes {
    padding: var(--spell-padding);
    padding-top: var(--spell-padding-small);
    padding-bottom: var(--spell-padding-bottom);

    color: var(--spell-notes-color);
    font-style: italic;
    font-size: calc(var(--p-size) * 0.8);
}

/* Spell Container */
.many-spells-container {
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start; */

    width: 100%;
    margin: 0 auto;
    height: 500px;
    /* height: 470px; */
    display: flex;
    flex-flow: column wrap; /* Shorthand – you could use ‘flex-direction: column’ and ‘flex-wrap: wrap’ instead */
    justify-content: flex-start;
    align-items: flex-start;
}
.many-spells-container .spell {
    max-width: 30%;
    display: block;
}

.item {
    background-color: orange;
    height: 150px;
    width: 31%;
    margin: 1%;
    padding: 10px;
  }
  
  .item:nth-child(2) {
    background-color: pink;
    height: 250px;
  }
  
  .item:nth-child(3) {
    height: 190px;
  }
  
  .item:nth-child(4) {
    background-color: aqua;
    height: 220px;
  }