
.promo-popup {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99999;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* width: 400px; */
    padding: var(--element-padding-half);

    border-radius: 25px;
    background: rgb(232,232,232,1);
    background: linear-gradient(-50deg, rgba(255,255,255,1) 0%, rgb(247, 236, 255) 100%);

    animation-name: PopupIntroAnimation;
    animation-duration: 1.25s;
    animation-timing-function: cubic-bezier(0.070, 0.540, 0.000, 1.005);
}
.promo-popup * {
    scale: 0.75;
}

@media (orientation: portrait) {
    .promo-popup {
        display: none;
    }
}

@keyframes PopupIntroAnimation {
    0% {
        bottom: -200px;
    }
    50% {
        bottom: -200px;
    }
    100% {
        bottom: 20px;
    }
}
