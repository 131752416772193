/* Copy Button */
.copy-button {
    position: absolute;
    bottom: 10px;
    right: 10px;

    width: calc(var(--p-size) * 1.25);
    height: calc(var(--p-size) * 1.25);

    opacity: 0.10;
}
.copy-button:hover {
    cursor: pointer;
    opacity: 0.25;
}
.copy-button--rotating {
    animation: CopyButtonRotating 1s linear infinite;
}
@keyframes CopyButtonRotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }