:root {
    --small-stat-padding-h: 11px;
    --small-stat-padding-v: 6px;
    --small-stat-font-size: var(--p-size);
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --small-stat-padding-h: calc(var(--small-stat-font-size) / 2 * 1.2);
        --small-stat-padding-v: calc(var(--small-stat-font-size) / 4 * 1.2);
    }
}

.small-stat-container {
    display: block;
    margin-top: 0px;
}
.small-stat {
    width: auto;
    border: solid 2px;
}





/* Size */
.small-stat--row    { flex-direction: row; }
.small-stat--row--normal {
    display: inline-flex; /* Does not take parent width and rescales with content size */
}
.small-stat--row--large {
    display: flex;
}
.small-stat--row--large div {
    flex: 1;
    text-align: center;
}

.small-stat--column {
    display: flex;
    flex-direction: column;
}
.small-stat--column--large {
    width: 100%;
}
.small-stat--column--large div {
    text-align: center;
}






/* Contents */
.small-stat div {   /* Affects both __name and __value */
    padding-top: calc(var(--small-stat-padding-v) + 1px);   /* For pixel-perfect alignment */
    padding-bottom: var(--small-stat-padding-v);
    padding-left: var(--small-stat-padding-h);
    padding-right: var(--small-stat-padding-h);
}
.small-stat__name {
    letter-spacing: 0.075em;
    font-family: var(--font-text);
    font-size: var(--small-stat-font-size);
    color: white;

}
.small-stat__value {
    margin: 0px;
    background-color: white;
    color: var(--dark-color);
    font-size: var(--small-stat-font-size);
    /* line-height: calc(var(--small-stat-font-size) * 1.1); */
}













/* For List variant of SmallStat */
.small-stat__value--list {
    padding: 0px !important;
    padding-left: 0px !important;   /* For clarity */
    padding-right: 0px !important;  /* For clarity */
}
.small-stat__value--list div {
    padding-top: 6px;
    padding-bottom: 6px;
}
.small-stat__value--list div:first-child { border-top: none; }
.small-stat__value--list--normal div { border-top: solid var(--darker-color) 2px; }
.small-stat__value--list--blue div { border-top: solid var(--dark-blue) 2px; }

.many-small-stats .small-stat--normal .small-stat__value {
    border-top: solid var(--darker-color) 2px;
}
.many-small-stats .small-stat--blue .small-stat__value {
    border-top: solid var(--dark-blue) 2px;
}