
* {
  vertical-align: top;
  box-sizing: border-box;
}

@media (orientation: landscape) {
  * {
    scroll-behavior: smooth;
  }
}

html {
  overflow-x: hidden; /* Prevents mobile scrolling to the right on some devices */
}

body {
  font-family: var(--font-text);
  font-size: var(--p-size);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

p {
  margin-top: 0px;
  /* margin-bottom: var(--element-padding); */
  line-height: 1.4em;
  font-size: var(--p-size);
  white-space: pre-wrap;
}

a {
  color: black;
  text-decoration: none;
}

#Window {
  background-color: #222;
  width: 100%;
  min-height: calc(100vh - var(--nav-height));
  margin: 0px;
  padding: 0px;
}
#Window-Content {
  padding-top: var(--nav-height);
}

.footer {
  min-height: 175px;
  background-color: var(--dark-gray-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}

.footer img {
  display: block;
  
  height: 50px;
  width: 50px;

  filter: grayscale(0.75) opacity(0.5);
  cursor: pointer;
}
.footer img:hover {
  filter: none;
  opacity: 1;
}







