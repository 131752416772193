
.anchor-fixer {
    margin-top: calc(-1 * var(--nav-height));
    padding-bottom: var(--nav-height);
}
.anchor-fixer.less {
    margin-top: calc(-0.65 * var(--nav-height));
    padding-bottom: calc(0.65 * var(--nav-height));
}
@media (orientation: portrait) {
    .anchor-fixer {
        height: 0px;
    }
}