.page-h2 {
    margin-top: calc(var(--element-padding) * 2);
    margin-bottom: var(--element-padding);
    position: relative;
    z-index: 1;
}
/* .page-h2 img {
    position: absolute;
    left: calc(0px - var(--page-padding) / 2);
    height: 75px;
    z-index: -1;
} */
.page-h2 h2 {
    margin: 0px;
    /* line-height: calc(var(--h2-size) * 2 + 5px); */
    font-weight: bold;
    font-size: var(--h2-size);
    font-family: HomeFont;
    color: var(--dark-color);
}
.page-h2--underline {
    width: 55%;
    height: 0.65vh;
    background: var(--dark-color);
    background: linear-gradient(90deg, var(--dark-color) 0%, var(--dark-color) 70%, rgba(255,0,0,0) 100%);
}

@media (orientation: portrait), (max-width: 900px)  {
    /* .page-h2 img {
        left: calc(15px - var(--page-padding) / 2);
    } */
    .page-h2--underline {
        height: 0.75vh;
    }
}