
:root {
    --table-row-side-padding: 12px;
    --table-td-min-width: 200px;
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --table-td-min-width: 0px;
    }
}
.table-normal-container-wrapper * {
    font-size: var(--p-size);
}
.table-normal-container-wrapper {
    display: block;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}
.table-normal-container {
    border: solid var(--medium-color) 2px;
    padding: 2px;
    display: block;
}
.table-normal-container--no-border {
    border: none;
    padding: 0px;
}
.table-normal-container table {
    table-layout: fixed;            /* Forces all cells to have their own width */
    border-collapse: separate;      /* Not sure */
    width: 100%;
}
.table-normal__header-row-container table {
    border-spacing: 0 4px;          /* Space between rows */
}
.table-normal-wrapper table {
    border-spacing: 0 8px;          /* Space between rows */
}
.table-normal-container td, .table-normal-container th {
    text-align: center;
    padding: 4px;
    border: none;
    margin: 0px;
    min-width: var(--table-td-min-width);
}
.table-normal-container th {
    font-family: TitleFont;
    font-weight: 100;
}

.table-normal__header-row-container {
    background-color: var(--medium-color);
    color: white !important;
    padding-left: var(--table-row-side-padding);
    padding-right: var(--table-row-side-padding);
}
.table-normal-wrapper {
    padding-left: var(--table-row-side-padding);
    padding-right: var(--table-row-side-padding);
}

/* Row Styling */
.table-normal-wrapper table tr:nth-child(even) {
    background-color: white;
    color: black;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15); 
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
}
.table-normal-wrapper table tr:nth-child(odd) {
    background-color: rgb(232, 232, 232);
    color: black;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.075); 
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.075);
}
.table-normal-wrapper--non-alternating table tr {
    background-color: white;
    color: black;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15); 
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
}
td:first-child, th:first-child {    /* For rounded borders */
  border-radius: 2px 0 0 2px;
}
td:last-child, th:last-child {
  border-radius: 0 2px 2px 0;
}


/* Width Configuration */
.table--info td:nth-child(1), .table--info th:nth-child(1) { width: 25%; vertical-align: baseline; }
.table--info td:nth-child(2), .table--info th:nth-child(2) { width: 75%; }

.table--info-reverse td:nth-child(1), .table--info-reverse th:nth-child(1) { width: 75%; }
.table--info-reverse td:nth-child(2), .table--info-reverse th:nth-child(2) { width: 25%; vertical-align: baseline; }