.page-h3 h3 {
    font-weight: bold;
    letter-spacing: 0.05rem;
    font-family: HomeFont;
    font-size: var(--h3-size);
    color: var(--dark-color);
    line-height: 1.4em;
    margin-top: 0px;
    margin-bottom: 10px;
    text-decoration: underline;
}
