:root {
    /* --landing-page-separator-height: 6px; */
    --landing-page-separator-height: 8px;
}

.landing-page-separator {
    display: block;
    width: 100%;
    height: var(--landing-page-separator-height);
    margin-top: -1px;
    margin-bottom: -1px;

    
}
.landing-page-separator--shadowed {
    -webkit-box-shadow: 0px 2px 20px 6px rgba(0,0,0,0.89);
    -moz-box-shadow: 0px 2px 20px 6px rgba(0,0,0,0.89);
    box-shadow: 0px 2px 20px 4px rgba(13,0,0,0.89);
}
