
.class-image {
    position: absolute;
    z-index: 0;

    top: -20%;
    height: 125%;   /* 125% of container */
}
.class-image-portrait {
    width: 80%;
    margin: auto;
    display: block;
}