:root {
    --hero-button-base-width: 1069px;
    --hero-button-base-height: 104px;
    --hero-button-aspect-ratio: 1069/104;
    --hero-button-image-url: url('./Hero-Button-Background-Landscape.png');
}
@media (orientation: portrait) {
    :root {
        --hero-button-base-width: 706px;
        --hero-button-base-height: 104px;
        --hero-button-aspect-ratio: 706/104;
        --hero-button-image-url: url('./Hero-Button-Background-Portrait.png');
    }
}

.hero-button {
    position: relative;
    width: 100%;
    aspect-ratio: var(--hero-button-aspect-ratio);

    background-image: var(--hero-button-image-url);
    background-position: center;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: background-size 0.1s ease-out 50ms;
}
.hero-button:has(a:hover) {
    background-size: 102.5% 100%;
    cursor: pointer;
}

.hero-button > a {
    font-family: HomeFont;
    font-size: var(--home-text-size);
    text-align: center;
    color: var(--dark-color);

    pointer-events: auto;   /* for hover effect */
}

