
.monster {}

.monster-p-compact {
    line-height: 1.2em;
}

.monster-variants {
    position: absolute;
    width: 80%;
    text-align: center;
    top: -75px;

    color: gray;
    font-style: italic;
}
.monster-story-ideas {
    background-color: white;
    border-radius: 4px;
    border-bottom-right-radius: 30px;

    padding: 20px;
}

.monster .separator {
    margin-top: var(--element-padding-half);    /* Duplicate because this might be inside other things */
    margin-bottom: var(--element-padding-half); /* Duplicate because this might be inside other things */
}