:root {
    --banner-width: 300px;
    --banner-height: 400px;
}

.home-banner-1 {
    position: relative;
    width: var(--banner-width);
    height: var(--banner-height);
    overflow: hidden;
}
.home-banner-1 img {
    border-radius: 6px;
    position: absolute;
    z-index: 20;
}
.home-banner__dark-overlay {
    border-radius: 6px;
    position: absolute;
    width: var(--banner-width);
    height: var(--banner-height);
    z-index: 40;
    
    background-color: rgba(0, 0, 0, 0.55);
}
.home-banner__window {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 60;

    display: flex;
    align-items: flex-end;

    text-align: center;
    color: white;
    padding: 18px;
}
.home-banner-1 h2 {
    font-size: 1.15em;
    margin-bottom: 0px;
    font-weight: 100;
}






:root {
    --banner-3-width: 175px;
    --banner-3-height: 250px;
}
.home-banner-3 {
    width: var(--banner-3-width);
    height: var(--banner-3-height);

    border-radius: 6px;

    overflow: hidden;

    position: relative;
    display: flex;
    align-items: flex-end;

    cursor: pointer;
}
.home-banner-3__img-wrapper {
    width: var(--banner-3-width);
    height: var(--banner-3-height);
    position: absolute;
    overflow: hidden;
    border-radius: 6px;
}
.home-banner-3__bottom {
    pointer-events: none;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.55);

    z-index: 40;
}
.home-banner-3 h2 {
    color: white;
    font-size: 1em;
    font-weight: 100;
    text-align: center;
}

.home-banner-3__bottom img {
    animation: Home-Banner-3-Image-Shrink 0.15s forwards;
}
.home-banner-3 img:hover {
    animation: Home-Banner-3-Image-Grow 0.25s forwards;
}
@keyframes Home-Banner-3-Image-Grow {
    from {
        margin-left: 0px;
        margin-top: 0px;
        width: var(--banner-3-width);
        height: var(--banner-3-height);
    }
    to {
        margin-left: calc((-1) * var(--banner-3-width) * 0.1);
        margin-top: calc((-1) * var(--banner-3-height) * 0.1);
        width: calc(var(--banner-3-width) * 1.2);
        height: calc(var(--banner-3-height) * 1.2);
    }
}
@keyframes Home-Banner-2-Image-Shrink {
    from {
        margin-left: calc((-1) * var(--banner-3-width) * 0.1);
        margin-top: calc((-1) * var(--banner-3-height) * 0.1);
        width: calc(var(--banner-3-width) * 1.2);
        height: calc(var(--banner-3-height) * 1.2);
    }
    to {
        margin-left: 0px;
        margin-top: 0px;
        width: var(--banner-3-width);
        height: var(--banner-3-height);
    }
}




:root {
    --banner-2-width: 500px;
    --banner-2-height: 200px;
}

.home-banner-2 {
    width: var(--banner-2-width);
    height: var(--banner-2-height);

    border-radius: 6px;

    overflow: hidden;

    position: relative;
    display: flex;
    align-items: flex-end;

    cursor: pointer;
}
.home-banner-2__img-wrapper {
    width: var(--banner-2-width);
    height: var(--banner-2-height);
    position: absolute;
    overflow: hidden;
    border-radius: 6px;
}

.home-banner-2__bottom {
    pointer-events: none;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.55);

    z-index: 40;
}
.home-banner-2 h2 {
    color: white;
    font-weight: 100;
    text-align: center;
}



.home-banner-2 img {
    animation: Home-Banner-2-Image-Shrink 0.15s forwards;
}
.home-banner-2 img:hover {
    animation: Home-Banner-2-Image-Grow 0.25s forwards;
}
@keyframes Home-Banner-2-Image-Grow {
    from {
        margin-left: 0px;
        margin-top: 0px;
        width: var(--banner-2-width);
        height: var(--banner-2-height);
    }
    to {
        margin-left: calc((-1) * var(--banner-2-width) * 0.1);
        margin-top: calc((-1) * var(--banner-2-height) * 0.1);
        width: calc(var(--banner-2-width) * 1.2);
        height: calc(var(--banner-2-height) * 1.2);
    }
}
@keyframes Home-Banner-2-Image-Shrink {
    from {
        margin-left: calc((-1) * var(--banner-2-width) * 0.1);
        margin-top: calc((-1) * var(--banner-2-height) * 0.1);
        width: calc(var(--banner-2-width) * 1.2);
        height: calc(var(--banner-2-height) * 1.2);
    }
    to {
        margin-left: 0px;
        margin-top: 0px;
        width: var(--banner-2-width);
        height: var(--banner-2-height);
    }
}