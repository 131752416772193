.bottom-wave-effect-holder {
    height: 5vh;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    /* overflow-y: hidden;
    overflow-x: hidden; */
}

.bottom-wave {
    background: url(wave.svg) repeat-x;
    position: absolute;
    width: 1500px;
    bottom: -5px;
    left: 0;
    height: 50px;
    transform: translate(0, 0, 0);
    animation: Waving 17s ease infinite;
}

.bottom-wave:nth-of-type(2) {
    animation: Swelling 4s ease infinite;
    opacity: 1;
}

@keyframes Waving {
    0%, 100% { margin-left: -300px; }
    50% { margin-left: 300px;}
  }
  @keyframes Swelling {
    0%, 100% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 25px);
    }
  }