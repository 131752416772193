:root {
    --h1-size: 70px;
    --h2-size: 30px;
    --h3-size: 20px;
    --p-size: 16px;

    
  
    --font-text: TextFont;
    --font-title: TitleFont;
    --font-home: HomeFont;
}
@media (orientation: portrait), (max-width: 900px)  {
    :root {
        --p-size: 4.25vw;
        --h1-size: 13vw;
        --h2-size: 7.5vw;
        --h3-size: 6vw;
    }
}

@font-face {
    font-family: TextFont;
    src: url('fonts/LinuxLibertine/LinLibertine_R.ttf');
}
@font-face {
    font-family: TitleFont;
    src: url('fonts/DayRoman/DAYROM__.ttf');
}
@font-face {
    font-family: StylizedFont;
    src: url('fonts/InknutAntiqua/InknutAntiqua-Light.ttf');
}
@font-face {
    font-family: HomeFont;
    src: url(fonts/RobotoCondensed/RobotoCondensed-Regular.ttf);
}
@font-face {
    font-family: HomeFontBold;
    src: url(fonts/RobotoCondensed/RobotoCondensed-Bold.ttf);
}
@font-face {
    font-family: LogoFont;
    src: url(fonts/Yrsa/Yrsa-Bold.ttf);
}