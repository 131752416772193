
.rules-box {
    width: 100%;
    font-size: var(--p-size);
}

.rules-box .column {
    border: solid black 2px;
    padding: 2px;
    height: fit-content;
}
.rules-box .column:nth-child(1),
.rules-box .column:nth-child(2) {
    margin-right: -2px;
}


.rules-toc-h1 {
    padding: 8px;
    padding-left: 12px;
    margin-bottom: 2px;
    background-color: #181818;
    color: white;
    font-family: var(--font-home);
    font-size: var(--h3-size);
}
.rules-toc-h2 {
    padding: 8px;
    padding-left: 16px;
    margin-bottom: 2px;
    background-color: var(--medium-color);
    color: white;
}
.rules-toc-h3 {
    padding: 5px;
    padding-left: 16px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
}
.rules-toc-h3-bullet {
    height: calc(var(--p-size) * 0.75);
    margin-top: 3px;
    margin-right: 6px;
}
.rules-toc-h1 a, .rules-toc-h2 a {
    color: white;
}
.rules-toc-h3 a {
    color: black;
}