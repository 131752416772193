
:root {
    --bcsm-width: 64px;
}
@media (orientation: portrait) {
    .breadcrumb-side-menu-container {
        visibility: hidden;
    }
}

.breadcrumb-side-menu-container {
    position: absolute;
    z-index: var(--z-overlay);
    
    height: 100%;
    width: var(--bcsm-width);

    left: 16px;

    display: flex;
    justify-content: center;
    flex-direction: column;
}

.breadcrumb-side-menu {
    position: relative;
    width: 100%;
    padding-left: 16px;
    background-color: var(--dark-gray-color);

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.breadcrumb-arrow {
    position: absolute;
    width: var(--bcsm-width);
    left: 0px;
}
.breadcrumb-arrow.up {
    top: -32px;
}
.breadcrumb-arrow.down {
    bottom: -32px;
    transform: rotate(180deg);
}