.monster-ability__body {
    /* background-color: white; */
    /* background-image: linear-gradient(to bottom, white, rgb(233, 210, 210)); */
    flex: 1;
    padding: 7px;
    padding-left: 21px;
    margin-bottom: 10px;

    position: relative;
}
.monster-ability--active .monster-ability__body {
    border: solid 1px var(--medium-color);
    border-top: none;
    border-bottom-right-radius: 30px;
}
.monster-ability--passive .monster-ability__body {
    border: solid 1px var(--passive-dark-color);
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.monster-ability__banner {
    height: 6px;
    width: 100%;
}
.monster-ability--active .monster-ability__banner {
    background-color: var(--medium-color);
}
.monster-ability--passive .monster-ability__banner {
    background-color: var(--passive-dark-color);
}
.monster-ability__body h4 {
    margin: 0px;
    margin-bottom: 3px;
    font-size: 1.05em;
    font-family: TitleFont;
}
.monster-ability--active .monster-ability__body h4 {
    color: var(--medium-color);
}
.monster-ability--passive .monster-ability__body h4 {
    color: var(--passive-dark-color);
}
.monster-ability__body p {
    margin: 0px;
}
.monster-ability__damage {
    /* background-color: rgba(255, 209, 209, 0.5); */
    background-color: var(--medium-color);
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    margin-right: 8px;
}
.monster-ability__effect-name {
    font-weight: bold;
    font-style: italic;
    color: var(--medium-color);
}
.monster-ability__effect-desc {
    font-style: italic;
    color: var(--medium-color);
}
.monster-ability .inline-icon {
    height: 18px;
    margin-top: 5px;
    margin-right: 0px;
}
.monster-ability-p {
    line-height: 1.2em;
}