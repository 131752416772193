.page-h0 {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;

    padding-top: calc(var(--page-padding) * 1.5);
    padding-bottom: calc(var(--page-padding) * 1.5);
}

.page-h0 .title-text-wrapper {
    display: block;
    position: relative;
    margin: auto;
    left: 125px;

    line-height: 0.9em;
    font-size: 4em;
    letter-spacing: 0.025em;
    font-weight: 150;

    color: var(--dark-color);

    text-align: center;
}
.page-h0-img-left {
    position: absolute;
    top: 25px;
    left: -300px;
}
.page-h0-img-right {
    position: absolute;
    top: 25px;
    right: -300px;
}



.page-h0__img--2-lines {
    margin-top: 60px;
}
